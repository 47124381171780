import cookieService from './cookieService';
import axios from 'axios';

export class PaymentService {
  static async makePayment(
    productId,
    discountCode = null
  ) {
    const token = cookieService.getCookie('token');
    const config = {
      headers: {
        Authorization: token
      }
    };

    const url = process.env.VUE_APP_DOMAIN + '/rest/payment/payment';
    const data = {
      productId: productId
    };

    if (discountCode) {
      data.discountCode = discountCode;
    }

    return await axios.post(
      url,
      data,
      config
    );
  }
}

const paymentService = PaymentService;
export default paymentService;
