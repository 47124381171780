<template>
  <div class="container-fluid">
  <div class="row fm-background-navbar-not-logged-in">
    <div class="col-1"></div>
    <div class="col-10">
      <NavbarNotLoggedIn></NavbarNotLoggedIn>
      <div class="row text-center">
        <div class="col-3"></div>
        <div class="col-sm-6 fm-h3">
          Testuj kurs FunEnglish.pl bez ryzyka
        </div>
        <div class="col-3"></div>
      </div>

      <div class="row">
        <div class="col-3"></div>
        <div class="col-6 fm-text-2-3">
          Ciesz się nieograniczonym dostępem do kursu językowego FunEnglish.pl, a jeśli w ciągu 60 dni postanowisz zrezygnować, oddamy ci pieniądze bez zbędnych pytań.
        </div>
        <div class="col-3"></div>
      </div>

      <div class="row">
        <div class="col-2"></div>
        <div class="col-8">
          <div class="row">
            <div class="col-sm-4 p-0">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fm-text-2-2">3 miesiące</h5>
                  <img class="img-fluid" v-bind:src="'images/pricing/pricing1.png'">
                  <p v-if="!isPromotionCodeUsed" class="card-text"><span class="fm-h2">99 PLN</span></p>
                  <p v-if="!isPromotionCodeUsed" style="height: 4.5rem;"></p>
                  <p v-if="isPromotionCodeUsed" class="card-text"><span class="fm-h2 fm-strike-price">99</span><span class="fm-text-2-2">PLN</span></p>
                  <p v-if="isPromotionCodeUsed" class="card-text"><span class="fm-h2" style="color: red;">{{ getPromotionPrice(99) }}</span><span class="fm-text-2-2" style="color: red;">PLN</span></p>
                  <p class="card-text"><span class="fm-text-2-3">33 PLN / miesiąc</span></p>
                  <button v-on:click="selectPlan(1)" type="button" class="btn btn-primary">Wybierz plan</button>
                </div>
              </div>
            </div>
            <div class="col-sm-4 p-0">
              <div class="card" style="background-color: var(--blanched-almond)">
                <div class="card-body">
                  <h5 class="card-title fm-text-2-2">12 miesięcy</h5>
                  <img class="img-fluid" v-bind:src="'images/pricing/pricing1.png'">
                  <p v-if="!isPromotionCodeUsed" class="card-text"><span class="fm-h2">239 PLN</span></p>
                  <p v-if="!isPromotionCodeUsed" style="height: 4.5rem;"></p>
                  <p v-if="isPromotionCodeUsed" class="card-text"><span class="fm-h2 fm-strike-price">239</span><span class="fm-text-2-2">PLN</span></p>
                  <p v-if="isPromotionCodeUsed" class="card-text"><span class="fm-h2" style="color: red;">{{ getPromotionPrice(239) }}</span><span class="fm-text-2-2" style="color: red;">PLN</span></p>
                  <p class="card-text"><span class="fm-text-2-3">19,92 PLN / miesiąc</span></p>
                  <button v-on:click="selectPlan(2)" type="button" class="btn btn-primary">Wybierz plan</button>
                </div>
              </div>
            </div>
            <div class="col-sm-4 p-0">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title fm-text-2-2">24 miesiące</h5>
                  <img class="img-fluid" v-bind:src="'images/pricing/pricing1.png'">
                  <p v-if="!isPromotionCodeUsed" class="card-text"><span class="fm-h2">399 PLN</span></p>
                  <p v-if="!isPromotionCodeUsed" style="height: 4.5rem;"></p>
                  <p v-if="isPromotionCodeUsed" class="card-text"><span class="fm-h2 fm-strike-price">399</span><span class="fm-text-2-2">PLN</span></p>
                  <p v-if="isPromotionCodeUsed" class="card-text"><span class="fm-h2" style="color: red;">{{ getPromotionPrice(399) }}</span><span class="fm-text-2-2" style="color: red;">PLN</span></p>
                  <p class="card-text"><span class="fm-text-2-3">16,63 PLN / miesiąc</span></p>
                  <button v-on:click="selectPlan(3)" type="button" class="btn btn-primary">Wybierz plan</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>

      <div style="height: 5rem;"></div>
      <div class="row text-center" style="padding-top: 3rem; height: 8rem;">
        <div class="col-4"></div>
        <div class="col-4">
          <p class="fm-disclaimer">angielski dla dzieci | © 2022 FunEnglish.pl</p>
        </div>
        <div class="col-4">
        </div>
      </div>
    </div>
    <div class="col-1"></div>
  </div>
  </div>
</template>

<script>
import NavbarNotLoggedIn from '../components/NavbarNotLoggedIn';
import PaymentService from '../services/paymentService';
import CookieService from '../services/cookieService';

export default {
  name: 'Pricing',
  components: { NavbarNotLoggedIn },
  data() {
    return {
      isUserLoggedIn: false,
      percentageDiscount: null,
      isPromotionCodeUsed: null,
      promotionCode: null,
      salesPackageCodeType: null
    };
  },
  mounted() {
    this.isUserLoggedIn = this.$store.state.isUserLoggedIn;
    this.promotionCode = CookieService.getCookie('salesPackageCode');
    this.percentageDiscount = CookieService.getCookie('salesPackageCodePercentageDiscount');
    this.salesPackageCodeType = CookieService.getCookie('salesPackageCodeType');
    this.isPromotionCodeUsed = !!this.percentageDiscount;
  },
  methods: {
    async selectPlan(productId) {
      if (this.isUserLoggedIn) {
        const response = await PaymentService.makePayment(
          productId,
          (this.salesPackageCodeType === 'discount') ? this.promotionCode : null
        );

        if (response.data.isSuccess) {
          const redirectUrl = response.data.redirectUrl;
          this.redirectToPaymentPage(redirectUrl);
        }
      } else {
        await this.$router.push('register');
      }
    },
    redirectToPaymentPage(paymentPageUrl) {
      window.location.replace(paymentPageUrl);
    },
    getPromotionPrice(standardPrice) {
      if (this.isPromotionCodeUsed) {
        return ((100 - this.percentageDiscount) * standardPrice) / 100;
      }
    }
  }
};
</script>

<style scoped>
.fm-h2 {
  color: var(--navy-blue);
  font-size: 3rem;
  font-weight: 800;
  margin-top: 4rem;
}

.fm-h3 {
  color: var(--navy-blue);
  font-size: 2rem;
  font-weight: 800;
  margin-top: 1.5rem;
}

.fm-text-2-2 {
  color: var(--navy-blue);
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 3rem;
}

.fm-text-2-3 {
  color: var(--navy-blue);
  font-size: 1rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.fm-background-navbar-not-logged-in {
  background-image: linear-gradient(var(--blanched-almond), white);
 /*background-color: var(--blanched-almond);*/
}

.fm-strike-price {
  text-decoration: line-through;
}
</style>
